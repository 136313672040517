<template>
    <div class="px-2 container-with-fixed-bottom">
        <div class="inner-container">
            <v-container>
                <v-row no-gutters>
                    <v-col>
                        <p class="headline mb-2">Leveranciergegevens</p>
                        <div>{{intro}}</div>
                    </v-col>
                </v-row>
                <v-form ref="form">
                    <v-row no-gutters>
                        <v-col cols="12" class="header px-0 pt-3">
                            <span class="header-title">Accountgegevens</span>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Type
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <SupplierTypeDropdown :supplier="supplier.supplierType" @changed="supplier.supplierType = $event" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            E-mailadres
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                            class="ma-0 pa-0"
                            :rules="[rules.required, rules.email]"
                            required
                            v-model="supplier.contactPersonAccountInformation.loginInformation.emailAddress"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Note
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                :rules="[rules.required]"
                                required
                                v-model="supplier.note"></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- Person Details -->
                    <v-row no-gutters>
                        <v-col cols="12" class="mb-0 py-5">
                            <v-divider color="white"></v-divider>
                        </v-col>
                        <v-col cols="12" class="header px-0">
                            <span class="header-title">Persoonsgegevens</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Voornaam
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                :rules="[rules.required]"
                                required
                                v-model="supplier.contactPersonAccountInformation.basicPersonInformation.firstname"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Achternaam
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                :rules="[rules.required]"
                                required
                                v-model="supplier.contactPersonAccountInformation.basicPersonInformation.lastName"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Telefoonnummer
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                :rules="[rules.required]"
                                required
                                v-model="supplier.contactPersonAccountInformation.basicPersonInformation.phoneNumber"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters> 
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Geboortedatum
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <DatepickerFormatted :date="getDateOnly(supplier.contactPersonAccountInformation.basicPersonInformation.dateOfBirth)" :showIcon="true" :isBirthdate="true"
                                @changed="supplier.contactPersonAccountInformation.basicPersonInformation.dateOfBirth = $event" />
                        </v-col>
                    </v-row>

                    <!-- Address -->
                    <v-row no-gutters>
                        <v-col cols="12" class="mb-0 py-5">
                            <v-divider color="white"></v-divider>
                        </v-col>
                        <v-col cols="12" class="header px-0">
                            <span class="header-title">Adresgegevens</span>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Straatnaam
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                v-model="supplier.contactPersonAccountInformation.addressInformation.street"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Huisnummer
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                v-model="supplier.contactPersonAccountInformation.addressInformation.houseNumber"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Postcode
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                :rules="[rules.required]"
                                required
                                v-model="supplier.contactPersonAccountInformation.addressInformation.zipCode"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Plaatsnaam
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                :rules="[rules.required]"
                                required
                                v-model="supplier.contactPersonAccountInformation.addressInformation.city"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Land
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0"
                                :rules="[rules.required]"
                                required
                                v-model="supplier.contactPersonAccountInformation.addressInformation.country"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            Bedrijfsnaam
                        </v-col>
                        <v-col cols="6" class="ma-auto py-2 px-0">
                            <v-text-field flat
                                class="ma-0 pa-0" 
                                :rules="[rules.required]"
                                required
                                v-model="supplier.contactPersonAccountInformation.addressInformation.company"
                                ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </div>
        <div class="fixed-bottom-section">
            <v-container>
                <v-row>
                    <v-col class="12 pt-0 mx-2">
                        <v-btn color="primary" block @click="onSaveSupplier()">
                        Leverancier toevoegen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import DatepickerFormatted from '@/components/common/DatepickerFormatted';
import SupplierTypeDropdown from '@/components/common/options/SupplierTypeDropdown';
export default {
    name: 'LeverancierDetailsForm',
    components: 
    {
        DatepickerFormatted,
        SupplierTypeDropdown
    },
    props: {
        supplier: Object,
    },
    data: () => ({
        editIcon: require('@/assets/icons/edit.svg'),
        intro: "Hieronder ziet u al uw persoonlijke accountgegevens. Is er iets gewijzigd? U kunt het hieronder eenvoudig aanpassen.",
        email: '',
        valid: true,
        data: {
            email: 'ewoudvlieg@hotmail.com',
        },
       
    }),
    mixins: [DataMixin, RouteMixin, FormatMixin],
    mounted () 
    {
        this.$store.dispatch('accountModule/getEmailAddressFromAccountOwner', this.accountId)
        .then(email => {
            this.email = email;
        })
    },
    methods: 
    {
        onSaveSupplier()
        {
            const valid = this.$refs.form.validate();
            if(!valid)
            {
                return;
            }

            this.$emit('save');
        }
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}


</style>