<template>
    <div v-if="isAdmin">
        <LoadingIcon :isLoading="isLoading" />
        <LeverancierDetailsForm v-if="userId" :supplier="supplier" @save="addNewSupplier" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import LeverancierDetailsForm from "@/components/admin/LeverancierDetailsForm";
import LoadingIcon from '@/components/common/LoadingIcon';
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";

export default {
    name: 'LeverancierNieuw',
    components: 
    {
        LeverancierDetailsForm,
        LoadingIcon
    },
    data: () => ({
        isLoading: false,
        supplier: {
            supplierType: 0,
            note: "res",
            contactPersonAccountInformation: {
                loginInformation: {
                    emailAddress: "",
                    password: ""
                },
                basicPersonInformation: {
                    firstname: "",
                    lastName: "",
                    dateOfBirth: null,
                    phoneNumber: ""
                },
                addressInformation: {
                    street: "",
                    houseNumber: "",
                    zipCode: "",
                    city: "",
                    country: "",
                    company: ""
                }
            }
            }
    }),
    computed: {
        ...mapState('authorizedModule', ['isAdmin', 'userId']),
    },
    mixins: [DataMixin, RouteMixin, FormatMixin],
    mounted () 
    {
    },
    methods: 
    {
        addNewSupplier()
        {
            console.log(this.supplier);
            this.isLoading = true;
            this.$store.dispatch('supplierModule/addSupplier', this.supplier)
            .then(() => {
                this.isLoading = false;
                // redirect to suppliers.
                this.$store.commit('dialog/showDialog', 'Successfully saved new supplier.');
                this.goToSuppliers();
            })
            .catch(() => {
                this.isLoading = false;
                this.$store.commit('dialog/showDialog', 'Something went wrong. Saving new supplier unsuccessful.');
            })
        }  
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}
</style>